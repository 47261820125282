import Textarea from "./Textarea";
import React from 'react';
import Cookies from 'js-cookie';
import Airtable from 'airtable';
import Error from './Error';
import Loading from './Loading';
import Success from './Success';
import Alert from './Alert';

const airtableApiKey = "patEZrneS5Kl388hr.406e12730e137faf68979e5f9ced861a63573cf2421de7e95921ea3ea38977c0"
const base = new Airtable({apiKey: airtableApiKey}).base('apptUl6F2TB70YhuT');
const courses = base('courses')
const questions = base('questions')
const answers = base('answers')

type CourseProps = {
  courseSymbol: string
  emailWithAnswers: string
};

type CourseStates = {
  email: string,
  submissions: {
    question_id: string,
    question: string,
    answer: string,
  }[],
  courseSymbol: string,
  courseName?: string,
  cookieName: string,
  status?: 'loading' | 'error' | 'submitting' | 'success' | null,
};

const numRequiredAnswers = 5;

class Course extends React.Component<CourseProps, CourseStates> {
  constructor(props: CourseProps) {
    super(props);

    const courseSymbol: string = props.courseSymbol
    const cookieName: string = `altcademy_mock_interview_${courseSymbol}`
    
    this.state = {
      email: '',
      submissions: [],
      courseSymbol,
      cookieName,
    }
  }

  retrieveQuestions = async() => {
    return questions.select({
      view: "Grid view",
      filterByFormula: `course = "${this.state.courseSymbol}"`
    }).all().then(records => {
      return records
    })
  }

  countAnswers = () => {
    let count = 0
    this.state.submissions.forEach(submission => {
      if (submission.answer?.length > 0) {
        count += 1
      }
    })
    return count
  }

  createAnswers = async(
    submissions: {
      question_id: string,
      question: string,
      answer: string,
    }[],
    email: string,
    callback: Function,
  ) => {
    answers.create(submissions.map((submission) => ({
      fields: {
        email,
        question_id: [submission.question_id],
        value: submission.answer,
      }
    })), (err, records) => {
      if (err) {
        console.error(err);
      }
      callback()
    })
  }

  submit = async() => {
    const { email, submissions } = this.state

    this.setState({ status: 'submitting' })

    await this.createAnswers(submissions, email, () => {
      window.location.href = `${
        window.location.href
      }&emailWithAnswers=${email}`
    })
  }

  reset = () => {
    Cookies.remove(this.state.cookieName)
    window.location.reload()
  }

  componentDidMount() {
    const { cookieName, courseSymbol } = this.state
    const { emailWithAnswers } = this.props

    courses.select({
      filterByFormula: `{symbol} = '${courseSymbol}'`
    }).firstPage((err, records) => {
      if (err) { console.error(err); return; }

      if (records?.length !== 0) {
        this.setState({
          courseName: records?.[0]?.fields?.name as string,
        })
      } else {
        this.setState({ status: 'error' })
      }
    })
    
    // Cookies.set(cookieName, '')

    if (emailWithAnswers) {
      this.setState({
        email: emailWithAnswers,
      })

      answers.select({
        filterByFormula: `AND({email} = '${emailWithAnswers}', FIND('${courseSymbol}', {question.course}))`
      }).firstPage((err, records) => {
        if (err) { console.error(err); return; }
        
        if (records?.length !== 0) {
          this.setState({
            submissions: records!.map((record) => {
              const question = record.fields?.question as Array<string>
              const question_id = record.fields?.question_id as Array<string>
              return {
                question_id: question_id[0],
                question: question[0],
                answer: record.fields?.value as string,
              }
            }),
            status: 'success',
          })
        } else {
          // this.setState({ error: true })
        }
      })
    }

    if (Cookies.get(cookieName) !== undefined && Cookies.get(cookieName) !== '') {
      const submissions = JSON.parse(Cookies.get(cookieName) as string)
      this.setState({ submissions, status: null })
    } else {
      this.retrieveQuestions().then(questions => {
        this.setState({
          submissions: questions.map((question) => ({
            question_id: question.id,
            question: question.get('value') as string,
            answer: Cookies.get(`${cookieName}_${question}`) || '',
          })),
          status: null,
        }, () => {
          Cookies.set(cookieName, JSON.stringify(this.state.submissions), { expires: 365 })
        })
      })
    }
  }

  render() {
    const { courseName, cookieName, email, status } = this.state

    const success = status === 'success'

    const submitting = status === 'submitting'

    if (status === 'loading') {
      return <Loading />
    }
    
    // if (success) {
    //   Cookies.remove(this.state.cookieName)
    //   return <Success />
    // }

    if (status === 'error') {
      return <Error />
    }

    return (
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{courseName} Mock Interview Questions</h3>

            {!success && (
              <Alert message={`Your answers will be *automatically saved* in this browser, but you must submit by clicking the "Submit" button at the bottom.`} />
            )}

            {success && (
              <Success message={`You've already submitted your answers. Copy the URL and submit it in your assignment.`} />
            )}

            <div className="mt-6 grid grid-cols-auto gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-12">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    disabled={success}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </div>

              <div className="sm:col-span-12">
                {
                  this.state.submissions.map((submission) => {
                    return (
                      <div className="mt-10" key={submission.question_id}>
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                          {submission.question}
                        </label>
                        <div className="mt-1">
                          <Textarea
                            id={submission.question_id}
                            value={submission.answer}
                            disabled={success}
                            onChange={(id, value) => {
                              const submissions = this.state.submissions
                              const submission = submissions.find((submission) => submission.question_id === id)
                              if (submission) {
                                submission.answer = value
                              }
                              this.setState({ submissions }, () => {
                                Cookies.set(cookieName, JSON.stringify(this.state.submissions), { expires: 365 })
                              })
                            }}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5 pb-6" hidden={success}>
          <p className="text-right">
            {
              (submitting) && <b className="mt-1 text-sm text-green-500">
                Submitting... Please don't close this page!
              </b>
            }
            {
              (!submitting && this.countAnswers() >= numRequiredAnswers && email !== '') && <b className="mt-1 text-sm text-green-500">
                Ready to Submit
              </b>
            }
            {
              (!submitting && this.countAnswers() < numRequiredAnswers || email === '') && <b className="mt-1 text-sm text-red-500">
                Require at least {numRequiredAnswers} answers before submitting & Your email address
              </b>
            }
          </p>
          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={this.reset}
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              disabled={this.countAnswers() < numRequiredAnswers || email === ''}
              onClick={(e) => {
                e.preventDefault()

                this.submit()
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default Course;
