const Error = () => {
  return (
    <div className="Error my-6">
      <div className="text-center">
        <p>404. This page is not found.</p>
        <p>Please email us at hello@altcademy.com or message us on Slack.</p>
        <p>Thank you!</p>
      </div>
    </div>
  );
}

export default Error;
