import './App.css';
import Navbar from './components/Navbar';
import Course from './components/Course';
import Error from './components/Error';

const Main = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const courseSymbol: string = urlParams.get('courseSymbol') as string;
  const emailWithAnswers: string = urlParams.get('emailWithAnswers') as string;

  return (
    <div className="Main">
      <Navbar />

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          {courseSymbol ? <Course
            courseSymbol={courseSymbol}
            emailWithAnswers={emailWithAnswers}
          /> : <Error />}
        </div>
      </div>
    </div>
  );
}

export default Main;
