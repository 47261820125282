export default function Textarea({
  id, value = '', onChange = () => {}, disabled = false
}:{
  id: string, value: string, disabled?: boolean, onChange: (id: string, value: string) => void
}) {
  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form action="#" className="relative">
          <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <textarea
              rows={5}
              name={`${id}`}
              id={`${id}`}
              value={value}
              disabled={disabled}
              onChange={(e) => onChange(id, e.target.value)}
              className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
              placeholder="Add your answer..."
              defaultValue={''}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
